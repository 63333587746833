<template>
    <div class="contents">
        <Tabs/>
        <br>
        <br>
        <div>
            <router-link class="list-entry-with-icon clickable" v-for="project in projects" :key="project.id"
                         :to="'/developers/projects/'+project.id">
                <img :src="project.image+'?nocache='+Date.now()"/>
                <div class="list-entry-with-icon-info">
                    <h1>{{ project.name }}</h1>
                    <p>Your project</p>
                </div>
            </router-link>
        </div>
        <br>
        <a @click="$refs.createProject.open()" class="button">Add Project</a>
        <Modal ref="createProject" mtitle="Add Project">
            <span class="label">NAME</span>
            <input autofocus v-model="createProjectName" type="text" class="input" placeholder="name">
            <a class="button" @click="createProject">Create Project</a>
        </Modal>
    </div>
</template>

<script>
import Tabs from "../../components/developer/Tabs";
import Modal from "../../components/Modal";

export default {
    data: () => ({
        color: "#434343",
        createProjectName: "",
        openedDropdown: "",
        projects: []
    }),
    created() {
        this.load()
    },
    components: {
        Tabs,
        Modal
    },
    methods: {
        createProject() {
            this.apiClient.post("/api/v2/projects", {
                name: this.createProjectName
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.createProjectName = ""
                        this.$refs.createProject.close()
                        this.load()
                    }
                })
        },
        load() {
            this.apiClient.get("/api/v2/projects")
                .then(res => res.json())
                .then(res => {
                    if (res.success)
                        this.projects = res.data
                })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>